<script>
import KpiStatEditableBase from '@/components/kpis/statsEditable/KpiStatEditableBase'
import { calculateRoi } from '@/utils/kpiUtils.js'

export default {
  extends: KpiStatEditableBase,
  props: {
    totalInvestment: {
      type: Number,
      default: 0
    },
    prValue: {
      type: Object,
      default: () => ({ is_active: false, value: 0 })
    }
  },
  computed: {
    roiValue () {
      const prValue = this.prValue.value || 0
      const totalInvestment = this.totalInvestment
      if (!prValue || !this.totalInvestment) return 0
      return calculateRoi(prValue, totalInvestment)
    }
  },
  watch: {
    roiValue (value) {
      this.computedValue = value
    },
    'prValue.is_active': function (value) {
      if (!value) this.isActiveComputed = false
    }
  },
  methods: {}
}
</script>
