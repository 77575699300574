import { KPI_TYPES } from '@/constants/KpiTypes'

export const kpiStatFactory = (type) => ({
  'name': type,
  'target': 0,
  'current_total': 0,
  'formatted_total': '0%',
  'ptd': 0,
  'pct': 0,
  'is_active': false,
  'created_at': null,
  'updated_at': null
})

export const marketFactory = () => ({
  'id': null,
  'kpis': {
    'roi': kpiStatFactory('roi'),
    'pr_value': kpiStatFactory('pr_value'),
    'impressions': kpiStatFactory('impressions'),
    'penetration_factor': kpiStatFactory('penetration_factor'),
    'amount_of_clippings': kpiStatFactory('amount_of_clippings'),
    'aplus_clippings': kpiStatFactory('aplus_clippings')
  },
  'country': {
    'data': {
      'iso_alpha2': null,
      'name': ''
    }
  }
})

export const KpiStatFormFactory = type => ({
  'name': type,
  'value': 0,
  'is_active': false
})

export const marketFormFactory = () => ({
  id: null,
  iso_alpha2: null,
  kpis: KPI_TYPES.reduce((all, current) => {
    all[current] = KpiStatFormFactory(current)
    return all
  }, {})
})

export const investmentFactory = () => ({
  name: '',
  value: 0
})
