<script>
import KpiStatEditableBase from '@/components/kpis/statsEditable/KpiStatEditableBase'
import { calculatePenetrationFactor } from '@/utils/kpiUtils.js'

export default {
  extends: KpiStatEditableBase,
  props: {
    country: {
      type: String,
      default: ''
    },
    impressions: {
      type: Object,
      default: () => ({ value: 0, is_active: false })
    }
  },
  computed: {
    marketPopulation () {
      if (!this.country) return 0
      return this.$store.getters.getCountryByIso2(this.country).population
    },
    // calculate ROI based on impressions
    penetrationFactor () {
      const impressions = this.impressions.value
      const marketPopulation = this.marketPopulation
      if (!impressions || !marketPopulation) return 0

      return calculatePenetrationFactor(impressions, marketPopulation)
    }
  },
  watch: {
    penetrationFactor (value) {
      // If we this stat is dominant, we dont update its value
      if (!this.isDominant) this.computedValue = value
    },
    'impressions.is_active': function (newValue) {
      if (!newValue) this.isActiveComputed = false
    }
  }
}
</script>
