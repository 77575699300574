<template>
  <form class="KpiInvestment is-flex KpiInvestment--new" @submit.prevent="addInvestment">
    <button class="button is-dark m-r-s" type="submit">
      <div class="icon is-large">
        <i class="hf hf-plus" />
      </div>
    </button>
    <div class="KpiInvestment__body p-a-s has-background-grey-lighter">
      <div class="is-flex is-aligned-justify">
        <div class="KpiInvestment__body-name">
          <div class="field">
            <div class="control">
              <form-field
                :validator="$v.investment.name"
                :attribute="$t('components.performance.kpi_edit.kpi_investment_dialog.investment_name')"
              >
                <input
                  ref="investment_name"
                  v-model="investment.name"
                  :placeholder="$t('components.performance.kpi_edit.kpi_investment_dialog.investment_name')"
                  type="text"
                  class="input"
                  name="investment_name"
                  @input="$v.investment.name.$touch()"
                >
              </form-field>
            </div>
          </div>
        </div>
        <div class="KpiInvestment__body-value">
          <form-field
            :validator="$v.investment.value"
            :attribute="$t('components.performance.kpi_edit.kpi_investment_dialog.investment_value')"
            class="has-addons"
          >
            <template slot="control">
              <div class="control is-expanded">
                <input
                  v-model.number="investment.value"
                  :placeholder="$t('general.cost')"
                  type="text"
                  class="input"
                  @input="$v.investment.value.$touch()"
                >
              </div>
              <div class="control">
                <button class="button is-static-white">
                  {{ currencySymbol }}
                </button>
              </div>
            </template>
          </form-field>
        </div>
      </div>
    </div>
    <div class="KpiInvestment__icon">
      <span class="icon icon is-large" />
    </div>
  </form>
</template>
<script>
import { investmentFactory } from '@/factories/kpi'
import { currencySymbol } from '@/utils/currencySymbol'
import { required, numeric } from 'vuelidate/lib/validators'

export default {
  validations: {
    investment: {
      name: { required },
      value: { required, numeric }
    }
  },
  data () {
    return {
      investment: investmentFactory()
    }
  },
  computed: {
    currencySymbol () {
      return currencySymbol()
    },
    isTouched () {
      return !!(this.investment.name || this.investment.value)
    }
  },
  methods: {
    addInvestment () {
      this.$v.investment.$touch()
      if (this.$v.investment.$error) return
      this.$emit('save', this.investment) // send to parent
      this.investment = investmentFactory() // reset
      this.$v.investment.$reset()
      this.$refs.investment_name.focus()
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiInvestment {
  align-items: center;
  margin-bottom: $margin;
  &__body {
    flex: 1 0 auto;
  }
  &__body-value {
    flex: 0 1 200px;
  }
}
</style>
