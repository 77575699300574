<template>
  <div class="KpiEdit">
    <banded-section
      v-loading="isLoading"
      :title="$t('pages.performance.kpi_edit.sidebar_title')"
      :tooltip="$t('pages.performance.kpi_edit.sidebar_description')"
      :collapsable="false"
    >
      <template v-if="campaign">
        <div class="columns">
          <div class="column is-narrow">
            <round-avatar
              :src="campaign.brand.data.logo.cropped"
              :acronym-fallback="campaign.brand.data.name"
              class="m-t-l"
              size="small"
            />
          </div>
          <div class="column is-6-tablet is-4-desktop">
            <form-field
              :validator="$v.form.name"
              :label="$t('forms.name_kpis')"
              :attribute="$t('forms.kpis_name')"
            >
              <input
                v-model="form.name"
                :placeholder="$t('general.name')"
                class="input"
                type="text"
                name="kpi_name"
                @input="$v.form.name.$touch()"
              >
            </form-field>
            <template v-if="campaign.start_date || campaign.end_date">
              {{ publicationDate(campaign.start_date) }} - {{ publicationDate(campaign.end_date) }}
            </template>
          </div>
        </div>
        <hr class="m-v-l">
        <div class="KpiEdit__markets">
          <KpiCampaignMarketEditable
            v-for="(market, index) in form.markets"
            :key="market.id"
            :v="$v.form.markets.$each[index]"
            :market="market"
            class="m-b-l"
            @update:market="handleMarketUpdate"
            @delete:market="handleMarketDelete"
          />
        </div>
        <div class="is-flex is-aligned-justify m-b-m">
          <button
            v-if="!addingNewMarket && !shouldAddNewMarket"
            class="button is-dark"
            data-testid="add-new-kpi"
            @click="addingNewMarket = true"
          >
            {{ $t('forms.add_market') }}
          </button>
          <button
            :class="{ 'is-loading': isLoading }"
            :disabled="!updatedMarketsList.length && !$v.form.name.$dirty"
            class="button is-primary m-l-a"
            data-testid="update-campaign"
            @click="updateAllMarkets"
          >
            {{ $t('forms.update_kpis') }}
          </button>
        </div>
        <div class="KpiEdit__addNewMarket m-b-m">
          <div v-if="addingNewMarket || shouldAddNewMarket" class="KpiEdit__newMarket">
            <KpiCampaignMarketEditable
              :v="$v.newMarket"
              :market.sync="newMarket"
            />
            <div class="has-text-right m-t-m">
              <button
                :class="{ 'is-loading': isActivating }"
                class="button is-primary"
                data-testid="activate-kpi"
                @click="activateMarket"
              >
                {{ $t('general.activate') }}
              </button>
            </div>
          </div>
        </div>
        <div class="title is-4">
          {{ $t('pages.performance.kpi_edit.kpi_totals') }}
        </div>
        <KpiEditTotals :markets="form.markets" />
      </template>
    </banded-section>
    <KpiInvestmentsDialog
      @investments-updated="handleInvestmentsUpdate"
    />
  </div>
</template>

<script>
import KpiCampaignMarketEditable from '@/components/kpis/KpiCampaignMarketEditable'
import KpiInvestmentsDialog from '@/components/kpis/KpiInvestmentsDialog'
import KpiEditTotals from '@/components/kpis/KpiEditTotals'
import { KPI_TYPES } from '@/constants/KpiTypes'
import { marketFormFactory } from '@/factories/kpi'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import PublicationDateMixin from '@hypefactors/shared/js/mixins/PublicationDateMixin'

export default {
  components: {
    KpiCampaignMarketEditable,
    KpiEditTotals,
    KpiInvestmentsDialog
  },
  mixins: [PublicationDateMixin],
  props: {
    kpiId: {
      type: [String, Number],
      default: ''
    }
  },
  validations: {
    form: {
      name: { required },
      markets: {
        $each: {
          kpis: {
            $each: {
              value: { required, numeric (v) { return !isNaN(parseFloat(v)) && isFinite(v) } }
            }
          }
        }
      }
    },
    newMarket: {
      iso_alpha2: { required },
      kpis: {
        $each: {
          value: { required, numeric (v) { return !isNaN(parseFloat(v)) && isFinite(v) } }
        }
      }
    }
  },
  data () {
    return {
      campaign: null,
      form: {
        name: '',
        markets: {}
      },
      isLoading: false,
      addingNewMarket: false,
      updatedMarketsList: [],
      newMarket: marketFormFactory(),
      isActivating: false
    }
  },
  computed: {
    ...mapGetters(['convertCurrency']),
    shouldAddNewMarket () {
      return !this.form.markets.length
    }
  },
  watch: {
    activeBrandId () {
      this.$router.push({ name: 'performance.kpi' })
    },
    'newMarket.iso_alpha2': function (value) {
      value && this.activateMarket()
    }
  },
  beforeRouteLeave (from, to, next) {
    if (this.updatedMarketsList.length !== 0) {
      return this.$confirm(this.$t('pages.performance.kpi_edit.unsaved_changes'))
        .then(() => next())
        .catch(() => {})
    }
    next()
  },
  async mounted () {
    await this.fetchKpiCampaign()
    if (this.campaign.brand.data.id !== this.activeBrandId) {
      this.$notify.warning('Kpi is not a part of the active brand.')
      return this.$router.push({ name: 'performance.kpi' })
    }
    if (!this.form.markets.length) {
      this.addingNewMarket = true
    }
    if (!this.campaign || this.campaign.is_completed) {
      this.$router.push({ name: 'performance.kpi' })
      this.$notify.warning(this.$t('warnings.cannot_edit_completed_campaign'))
    }
  },
  methods: {
    fetchKpiCampaign () {
      this.isLoading = true
      return this.$api.get(`kpi-campaigns/${this.kpiId}?include[]=markets&include[]=brand`)
        .then(response => {
          this.isLoading = false
          this.campaign = response.data.data
          this.form = this.transformCampaignMarketsToForm(this.campaign)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.cannot_fetch_campaign'))
        })
    },
    transformCampaignMarketsToForm (campaign) {
      const markets = campaign.markets.data
      try {
        return {
          name: campaign.name,
          markets: markets.map(this.transformMarketToFormReady)
        }
      } catch (err) {
        console.log(err)
      }
    },
    transformMarketToFormReady (market) {
      return {
        id: market.id,
        iso_alpha2: this.$safeGet(market, 'country.data.iso_alpha2', 0),
        kpis: this.transformMarketKpisToFormReady(market.kpis),
        // We are leaving the investments not transformed to the user currency because we do that during display or the moment we open the Investments Dialog.
        // This makes it easier when saving the investments
        investments: market.investments.data
      }
    },
    transformMarketKpisToFormReady (kpis) {
      return KPI_TYPES.reduce((all, type) => {
        let value = this.$safeGet(kpis, `${type}.target`, 0)
        if (type === 'pr_value') {
          value = this.convertCurrency(value)
        }
        all[type] = {
          name: type,
          is_active: this.$safeGet(kpis, `${type}.is_active`, false),
          value
        }
        return all
      }, {})
    },
    handleMarketUpdate (newMarket) {
      const index = this.form.markets.findIndex(m => m.id === newMarket.id)
      this.form.markets.splice(index, 1, newMarket)
      this.addToUpdatedMarketsList(newMarket.id)
    },
    activateMarket () {
      this.$v.newMarket.$touch()
      if (this.$v.newMarket.$error) return
      this.isActivating = true
      const payload = {
        ...this.newMarket,
        brand: this.activeBrandId
      }
      this.$api.post(`kpi-campaigns/${this.campaign.id}/markets`, payload)
        .then(response => {
          this.isActivating = false
          this.addNewMarket(response.data.data)
          this.addingNewMarket = false
          this.$v.newMarket.$reset()
        })
        .catch(error => {
          this.isActivating = false
          this.$displayRequestError(error, this.$t('errors.cannot_activate_market_for_campaign'))
        })
    },
    addNewMarket (market) {
      try {
        if (!market) return
        this.campaign.markets.data.push(market)
        this.form.markets.push(this.transformMarketToFormReady(market))
        this.newMarket = marketFormFactory()
      } catch (err) {
        console.log(err)
      }
    },
    updateAllMarkets () {
      this.$v.form.$touch()
      if (this.$v.form.$error) return
      const arrayOfMarketPromises = this.updatedMarketsList.map(marketId => this.updateMarketRequest(this.form.markets.find(m => m.id === marketId)))
      this.isLoading = true
      Promise.all([...arrayOfMarketPromises, this.updateCampaignRequest()])
        .then((response) => {
          const campaignResponse = response[response.length - 1]
          console.log(campaignResponse)
          this.isLoading = false
          this.$notify.success(this.$t('success.market_updated'))
          this.updatedMarketsList = []
          this.$router.push({ name: 'performance.kpi', query: { kpiId: campaignResponse.data.data.id } })
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.cannot_update_campaign'))
        })
    },
    updateMarketRequest (market) {
      const payload = {
        ...market,
        brand: this.activeBrandId
      }
      payload.kpis.pr_value.value = this.convertCurrency(payload.kpis.pr_value.value, this.$currencySymbol(), 'DKK') // convert back to dkk
      return this.$api.put(`kpi-campaigns/${this.campaign.id}/markets/${market.id}`, payload)
        .then(response => {
          return response.data.data
        })
        .catch(error => {
          this.$handleError(error)
          throw error
        })
    },
    updateCampaignRequest () {
      this.isLoading = true

      const payload = {
        ...this.form,
        brand: this.activeBrandId
      }
      delete payload.markets // remove the markets key as we dont need to there

      return this.$api.put(`kpi-campaigns/${this.campaign.id}`, payload)
    },
    handleInvestmentsUpdate (market) {
      const marketIndex = this.form.markets.findIndex(m => m.id === market.id)
      if (marketIndex === -1) return
      this.form.markets[marketIndex].investments = market.investments.data
      this.addToUpdatedMarketsList(market.id)
    },
    async handleMarketDelete (market) {
      try {
        await this.$confirm(this.$t('pages.performance.kpi_edit.confirm_market_deletion'))
      } catch (err) {
        return false
      }
      this.isLoading = true
      this.$api.delete(`kpi-campaigns/${this.kpiId}/markets/${market.id}`)
        .then(response => {
          this.isLoading = false
          this.removeMarketFromList(market)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.cannot_remove_market'))
        })
    },
    removeMarketFromList (market) {
      const marketIndex = this.form.markets.findIndex(m => m.id === market.it)
      this.form.markets.splice(marketIndex, 1)
      this.removeFromUpdatedMarketsList(market.id)
    },
    addToUpdatedMarketsList (marketId) {
      if (!this.updatedMarketsList.includes(marketId)) {
        this.updatedMarketsList.push(marketId)
      }
    },
    removeFromUpdatedMarketsList (marketId) {
      if (this.updatedMarketsList.includes(marketId)) {
        const marketIndexInUpdatedList = this.updatedMarketsList.findIndex(mid => mid === marketId)
        this.updatedMarketsList.splice(marketIndexInUpdatedList, 1)
      }
    }
  }
}
</script>
