<template>
  <div class="KpiInvestment is-flex">
    <div class="KpiInvestment__body p-a-s has-background-grey-lighter">
      <div class="is-flex is-aligned-justify is-aligned-middle">
        <div class="KpiInvestment__body-name">
          {{ investment.name }}
        </div>
        <div class="KpiInvestment__body-value">
          <form-field
            :validator="v.value"
            :attribute="$t('components.performance.kpi_edit.kpi_investment_dialog.investment_value')"
            class="has-addons"
          >
            <template slot="control">
              <div class="control is-expanded">
                <input
                  v-model.number="investmentValue"
                  :placeholder="$t('general.cost')"
                  type="text"
                  class="input"
                  @input="v.value.$touch()"
                >
              </div>
              <div class="control">
                <button class="button is-static-white">
                  {{ currencySymbol }}
                </button>
              </div>
            </template>
          </form-field>
        </div>
      </div>
    </div>
    <div class="KpiInvestment__icon">
      <a v-if="!defaultInvestmentTypes.includes(investment.name)" @click.prevent="removeInvestment">
        <span class="icon is-large"><i class="hf hf-close hf-fw hf-lg" /></span>
      </a>
      <div v-else class="icon is-large" />
    </div>
  </div>
</template>

<script>
import { investmentFactory } from '@/factories/kpi'
import { currencySymbol } from '@/utils/currencySymbol'
import { DEFAULT_INVESTMENTS } from '@/constants/KpiTypes'

export default {
  props: {
    investment: {
      type: Object,
      default: () => investmentFactory()
    },
    v: {
      type: Object,
      required: true
    },
    index: {
      type: [Number, String],
      required: true
    }
  },
  computed: {
    defaultInvestmentTypes () {
      return DEFAULT_INVESTMENTS().map(i => this.$t(i))
    },
    currencySymbol () {
      return currencySymbol()
    },
    investmentValue: {
      get () {
        return this.investment.value
      },
      set (value) {
        this.$emit('update:investment', { index: this.index, investment: { name: this.investment.name, value } })
      }
    }
  },
  methods: {
    removeInvestment () {
      this.$emit('remove', { index: this.index })
    }
  }
}
</script>
