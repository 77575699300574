<template>
  <el-dialog
    :title="$t('components.performance.kpi_edit.kpi_investment_dialog.title')"
    :visible.sync="visible"
    width="50%"
    class="KpiInvestmentsDialog"
  >
    <div class="KpiInvestments">
      <KpiInvestment
        v-for="(investment, index) in investments"
        :key="index"
        :investment="investment"
        :index="index"
        :v="$v.investments.$each[index]"
        @update:investment="updateInvestmentList"
        @remove="removeInvestment"
      />
      <KpiInvestmentCreate
        ref="KpiInvestmentCreate"
        @save="appendNewInvestment"
      />
    </div>
    <div class="KpiInvestments__total is-flex is-aligned-middle">
      <div
        data-testid="total_investments"
        class="KpiInvestments__total-body is-full-width is-flex is-aligned-justify  p-a-s has-background-grey-lighter"
      >
        <div>{{ $t('components.performance.kpi_edit.kpi_investment_dialog.total_investment') }}</div>
        <div>{{ formatMoney(totalInvestments, false) }}</div>
      </div>
      <div class="KpiInvestments__total-spacer">
        <div class="icon is-large" />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <button
        :class="{'is-loading': isLoading}"
        class="button is-primary"
        data-testid="saveInvestments"
        @click="updateInvestmentsRequest"
      >
        {{ $t('forms.save') }}
      </button>
    </div>
  </el-dialog>
</template>

<script>
import { investmentFactory } from '@/factories/kpi'
import KpiInvestment from '@/components/kpis/investments/KpiInvestment'
import KpiInvestmentCreate from '@/components/kpis/investments/KpiInvestmentCreate'
import { numeric, required } from 'vuelidate/lib/validators'
import { DEFAULT_INVESTMENTS } from '@/constants/KpiTypes'
import _unionBy from 'lodash/unionBy'
import { mapGetters } from 'vuex'
import FormatMoneyMixin from '@hypefactors/shared/js/mixins/FormatMoneyMixin'

export default {
  components: {
    KpiInvestment,
    KpiInvestmentCreate
  },

  mixins: [FormatMoneyMixin],

  validations: {
    investments: {
      $each: {
        value: { numeric, required }
      }
    }
  },
  data () {
    return {
      isLoading: false,
      visible: false,
      investments: [],
      marketId: null,
      newInvestment: investmentFactory()
    }
  },
  computed: {
    ...mapGetters(['convertCurrency']),
    totalInvestments () {
      return this.investments.reduce((all, current) => (all + parseFloat(current.value)), 0.0)
    }
  },
  mounted () {
    this.$bus.$on('KpiInvestmentsDialog:open', this.open)
  },
  beforeDestroy () {
    this.$bus.$off('KpiInvestmentsDialog:open')
  },
  methods: {
    open ({ id, investments }) {
      this.visible = true
      this.marketId = id
      investments = investments.map(investment => ({ ...investment, value: this.convertCurrency(investment.value) }))
      this.investments = _unionBy(investments, this.getDefaultInvestments(), 'name')
    },
    getDefaultInvestments () {
      return DEFAULT_INVESTMENTS().map(i => ({ name: this.$t(i), value: 0 }))
    },
    removeInvestment ({ index }) {
      this.investments.splice(index, 1)
    },
    appendNewInvestment (investment) {
      this.investments.push(investment)
    },
    updateInvestmentList ({ index, investment }) {
      this.investments.splice(index, 1, investment)
    },
    updateInvestmentsRequest () {
      this.isLoading = true
      const kpiCampaignId = this.$route.params.kpiId
      const investments = this.investments.map(investment => ({
        ...investment,
        value: this.convertCurrency(investment.value, this.$currencySymbol(), 'DKK')
      }))
      const payload = {
        investments
      }
      // Check if we have added a new investment without saving it and save it.
      if (this.$refs.KpiInvestmentCreate.isTouched) {
        this.$refs.KpiInvestmentCreate.addInvestment()
      }
      this.$api.put(`kpi-campaigns/${kpiCampaignId}/markets/${this.marketId}/investments`, payload)
        .then(response => {
          this.isLoading = false
          this.visible = false
          this.$notify.success(this.$t('success.investment_added'))
          this.$emit('investments-updated', response.data.data)
        })
        .catch(error => {
          this.isLoading = false
          this.$displayRequestError(error, this.$t('errors.cannot_save_investment'))
        })
    }
  }
}
</script>
