import { currencySymbol } from '@/utils/currencySymbol'

export const KPI_TYPES = [
  'roi',
  'pr_value',
  'impressions',
  'penetration_factor',
  'amount_of_clippings',
  'aplus_clippings'
]

export const KPI_OPTIONS = () => ({
  pr_value: {
    title: 'general.hypefactors_value',
    subtitle: 'general.hypefactors_value',
    description: 'components.kpi_types.kpi_options.pr_value_description',
    suffix: currencySymbol()
  },
  roi: {
    title: 'general.roi',
    subtitle: 'components.kpi_types.kpi_options.roi_subtitle',
    description: '',
    suffix: '%'
  },
  impressions: {
    title: 'general.impressions',
    subtitle: 'general.impressions',
    description: ''
  },

  penetration_factor: {
    title: 'general.reach_factor',
    subtitle: 'components.kpi_types.kpi_options.reach_factor_subtitle',
    description: '',
    suffix: '%'
  },
  amount_of_clippings: {
    title: 'general.clippings',
    subtitle: 'components.kpi_types.kpi_options.clippings_subtitle',
    description: ''
  },

  aplus_clippings: {
    title: 'general.aplus_clippings',
    subtitle: 'components.kpi_types.kpi_options.aplus_clippings_subtitle',
    description: '',
    suffix: '%'
  }
})

export const DEFAULT_INVESTMENTS = () => [
  'components.kpi_types.default_investments.team_costs', 'components.kpi_types.default_investments.office_costs', 'components.kpi_types.default_investments.agency_costs', 'components.kpi_types.default_investments.distribution_costs', 'components.kpi_types.default_investments.hospitality_costs', 'components.kpi_types.default_investments.additional_costs'
]
