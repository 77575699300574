<template>
  <Square :subtitle="description">
    <div slot="title" class="is-size-6">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div slot="footer">
      <div class="KpiStatTotal__target has-text-grey is-size-6 m-b-s">
        {{ $t('general.target') }}
      </div>
      <div class="has-text-primary has-text-weight-bold is-size-5 m-b-s">
        {{ value }} {{ suffix }}
      </div>
    </div>
  </Square>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    }
  }
}
</script>
