<template>
  <div class="KpiEditTotals m-b-m">
    <div class="columns is-mobile is-multiline is-variable-grid is-1">
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :title="$t(kpiOptions['pr_value'].title)"
          :description="$t(kpiOptions['pr_value'].description)"
          :value="sumTotals('pr_value')"
          :suffix="kpiOptions['pr_value'].suffix"
          class="is-full-width"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :title="$t(kpiOptions['roi'].title)"
          :description="$t(kpiOptions['roi'].description)"
          :value="sumTotals('roi')"
          :suffix="kpiOptions['roi'].suffix"
          class="is-full-width"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :title="$t(kpiOptions['impressions'].title)"
          :description="$t(kpiOptions['impressions'].description)"
          :value="sumTotals('impressions')"
          :suffix="kpiOptions['impressions'].suffix"
          class="is-full-width"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :title="$t(kpiOptions['penetration_factor'].title)"
          :description="$t(kpiOptions['penetration_factor'].description)"
          :value="sumTotals('penetration_factor')"
          :suffix="kpiOptions['penetration_factor'].suffix"
          class="is-full-width"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :title="$t(kpiOptions['amount_of_clippings'].title)"
          :description="$t(kpiOptions['amount_of_clippings'].description)"
          :value="sumTotals('amount_of_clippings')"
          :suffix="kpiOptions['amount_of_clippings'].suffix"
          class="is-full-width"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatTotal
          :description="$t(kpiOptions['aplus_clippings'].description)"
          :value="sumTotals('aplus_clippings')"
          :suffix="kpiOptions['aplus_clippings'].suffix"
          class="is-full-width"
        >
          <div slot="title" class="is-flex is-aligned-middle">
            <tier tier="aplus" />
            <span class="m-l-s">{{ $t('general.clippings') }}</span>
          </div>
        </KpiStatTotal>
      </div>
    </div>
  </div>
</template>

<script>
// import KpiStatEditableBase from '@/components/kpis/statsEditable/KpiStatEditableBase'
import KpiStatTotal from '@/components/kpis/stats/KpiStatTotal'
import { KPI_OPTIONS } from '@/constants/KpiTypes'

export default {
  components: {
    // KpiStatEditableBase,
    KpiStatTotal
  },
  props: {
    markets: {
      type: Array,
      required: true
    }
  },
  computed: {
    kpiOptions () {
      return KPI_OPTIONS()
    }
  },
  methods: {
    sumTotals (type) {
      // sum all market's KPI's values
      return this.markets.reduce((total, market) => {
        const kpi = market.kpis[type]
        const value = kpi.value
        // if its not active we skip it
        if (!kpi.is_active) return total
        // if value is not a number we skip it
        if (typeof value !== 'number') return total
        return total + value
      }, 0)
    }
  }
}
</script>
