<script>
import KpiStatEditableBase from '@/components/kpis/statsEditable/KpiStatEditableBase'
import { calculateImpressions } from '@/utils/kpiUtils.js'

export default {
  extends: KpiStatEditableBase,
  props: {
    country: {
      type: String,
      default: ''
    },
    penetrationFactor: {
      type: Object,
      default: () => ({ value: 0, is_active: false })
    }
  },
  computed: {
    marketPopulation () {
      if (!this.country) return 0
      return this.$store.getters.getCountryByIso2(this.country).population
    },
    // Calculate impressions based on penetration factor
    impressions () {
      const penetrationFactor = this.penetrationFactor.value
      const marketPopulation = this.marketPopulation
      if (!penetrationFactor || !marketPopulation) return 0

      return calculateImpressions(penetrationFactor, marketPopulation)
    }
  },
  watch: {
    impressions (value) {
      // If we this stat is dominant, we dont update its value
      if (!this.isDominant) this.computedValue = value
    }
  }
}
</script>
