<template>
  <div class="KpiCampaignMarketEditable">
    <div class="is-flex is-aligned-justify m-v-s">
      <div class="KpiCampaignMarketEditable__countryPicker">
        <form-field :validator="v.iso_alpha2" attribute="Country">
          <country-picker
            v-model="form.iso_alpha2"
            popper-class="KpiCampaignMarketEditable__countryModal"
          />
        </form-field>
      </div>
      <div class="KpiCampaignMarketEditable__removeMarket">
        <a
          v-if="!isNewMarket"
          class="has-text-weight-semibold has-text-primary"
          @click.prevent="$emit('delete:market', market)"
        >
          <span class="icon"><i class="hf hf-trash" /></span><span>{{ $t('general.delete') }}</span>
        </a>
      </div>
    </div>
    <div
      v-loading="isNewMarket"
      :element-loading-text="$t('pages.performance.kpi_edit.choose_country_first')"
      class="columns is-mobile is-multiline is-variable-grid is-1"
      element-loading-spinner="none"
      element-loading-custom-class="z-index-1"
    >
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditableBase
          :title="$t(kpiOptions.pr_value.title)"
          :description="$t(kpiOptions.pr_value.subtitle)"
          :is-active.sync="form.kpis.pr_value.is_active"
          :value.sync="form.kpis.pr_value.value"
          :suffix="kpiOptions.pr_value.suffix"
          :disable-switch="!form.iso_alpha2"
          :v="v.kpis.$each.pr_value"
          class="is-full-width"
          type="pr_value"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditableROI
          :title="$t(kpiOptions.roi.title)"
          :description="$t(kpiOptions.roi.subtitle)"
          :is-active.sync="form.kpis.roi.is_active"
          :value.sync="form.kpis.roi.value"
          :suffix="kpiOptions.roi.suffix"
          :v="v.kpis.$each.roi"
          :disable-switch="!form.kpis.pr_value.is_active || !form.iso_alpha2"
          :pr-value="form.kpis.pr_value"
          :total-investment="totalInvestment"
          class="is-full-width"
          type="roi"
          disabled-input
        >
          <el-tooltip
            :disabled="!disableInvestments"
            :content="$t('pages.performance.kpi_edit.investments_disabled')"
          >
            <button
              :disabled="disableInvestments"
              class="button is-dark is-small m-t-s"
              data-testid="openInvestments"
              @click="openInvestmentDialog"
            >
              <span v-if="totalInvestment">
                {{ $t('general.investment') }}: {{ abbrMoney(totalInvestment) }}
              </span>
              <span v-else>
                {{ $t('pages.performance.kpi_edit.add_investments') }}
              </span>
            </button>
          </el-tooltip>
        </KpiStatEditableROI>
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditableImpressions
          :title="$t(kpiOptions.impressions.title)"
          :description="$t(kpiOptions.impressions.subtitle)"
          :is-active.sync="form.kpis.impressions.is_active"
          :value.sync="form.kpis.impressions.value"
          :suffix="kpiOptions.impressions.suffix"
          :disable-switch="!form.iso_alpha2"
          :v="v.kpis.$each.impressions"
          :country="market.iso_alpha2"
          :penetration-factor="form.kpis.penetration_factor"
          :dominant="impressionsOrPenetrationFactorDominant"
          class="is-full-width"
          type="impressions"
          @switchDominant="switchImpressionsDominant"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditablePenetrationFactor
          :title="$t(kpiOptions.penetration_factor.title)"
          :description="$t(kpiOptions.penetration_factor.subtitle)"
          :is-active.sync="form.kpis.penetration_factor.is_active"
          :value.sync="form.kpis.penetration_factor.value"
          :suffix="kpiOptions.penetration_factor.suffix"
          :v="v.kpis.$each.penetration_factor"
          :country="market.iso_alpha2"
          :disabled-input="!form.kpis.impressions.is_active"
          :disable-switch="!form.kpis.impressions.is_active || !form.iso_alpha2"
          :impressions="form.kpis.impressions"
          :dominant="impressionsOrPenetrationFactorDominant"
          class="is-full-width"
          type="penetration_factor"
          @switchDominant="switchImpressionsDominant"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditableBase
          :title="$t(kpiOptions.amount_of_clippings.title)"
          :description="$t(kpiOptions.amount_of_clippings.subtitle)"
          :is-active.sync="form.kpis.amount_of_clippings.is_active"
          :value.sync="form.kpis.amount_of_clippings.value"
          :suffix="kpiOptions.amount_of_clippings.suffix"
          :disable-switch="!form.iso_alpha2"
          :v="v.kpis.$each.amount_of_clippings"
          class="is-full-width"
          type="amount_of_clippings"
        />
      </div>
      <div class="column is-6-mobile is-4-tablet is-3-desktop is-2-fullhd is-flex">
        <KpiStatEditableBase
          :description="$t(kpiOptions.aplus_clippings.subtitle)"
          :is-active.sync="form.kpis.aplus_clippings.is_active"
          :value.sync="form.kpis.aplus_clippings.value"
          :suffix="kpiOptions.aplus_clippings.suffix"
          :disable-switch="!form.iso_alpha2"
          :v="v.kpis.$each.aplus_clippings"
          class="is-full-width"
          type="aplus_clippings"
        >
          <div slot="title" class="is-flex is-aligned-middle">
            <tier tier="aplus" />
            <div class="m-l-s span">
              {{ $t('general.clippings') }}
            </div>
          </div>
        </KpiStatEditableBase>
      </div>
    </div>
  </div>
</template>

<script>
import { marketFormFactory } from '@/factories/kpi'
import _clone from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import { mapGetters } from 'vuex'
import KpiStatEditableBase from '@/components/kpis/statsEditable/KpiStatEditableBase'
import KpiStatEditableROI from '@/components/kpis/statsEditable/KpiStatEditableROI'
import KpiStatEditablePenetrationFactor from '@/components/kpis/statsEditable/KpiStatEditablePenetrationFactor'
import KpiStatEditableImpressions from '@/components/kpis/statsEditable/KpiStatEditableImpressions'
import { KPI_OPTIONS } from '@/constants/KpiTypes'
import FormatMoneyAbbrMixin from '@hypefactors/shared/js/mixins/FormatMoneyAbbrMixin'

export default {
  components: {
    KpiStatEditableBase,
    KpiStatEditableROI,
    KpiStatEditablePenetrationFactor,
    KpiStatEditableImpressions
  },

  mixins: [FormatMoneyAbbrMixin],

  props: {
    market: {
      type: Object,
      default: () => ({})
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: marketFormFactory(),
      kpiOptions: KPI_OPTIONS(),
      impressionsOrPenetrationFactorDominant: ''
    }
  },
  computed: {
    ...mapGetters(['countries']),
    isNewMarket () {
      return !this.market.id
    },
    totalInvestment () {
      if (!this.market.investments) return 0
      return this.market.investments.reduce((all, investment) => (all + investment.value), 0)
    },
    disableInvestments () {
      return !this.form.kpis.pr_value.is_active || !this.form.kpis.roi.is_active
    }
  },
  watch: {
    form: {
      deep: true,
      handler (value) {
        if (this.isTheSame(value)) return
        this.$emit('update:market', _clone(value))
      }
    },
    market: {
      deep: true,
      immediate: true,
      handler (value) {
        this.copyMarketLocally()
      }
    }
  },
  methods: {
    isTheSame (value = this.form) {
      return _isEqual(value, this.market)
    },
    copyMarketLocally () {
      if (this.isTheSame()) return
      this.form = Object.assign({}, this.form, _clone(this.market))
    },
    openInvestmentDialog () {
      if (this.isNewMarket) {
        this.$alert(this.$t('warnings.activate_your_market_before_adding_investments'), { type: 'warning' })
        return
      }
      this.$bus.$emit('KpiInvestmentsDialog:open', {
        id: this.market.id,
        investments: this.market.investments || []
      })
    },
    // Sets the dominant state for Impressions or Reach factor
    switchImpressionsDominant (property) {
      this.impressionsOrPenetrationFactorDominant = property
    }
  }
}
</script>
