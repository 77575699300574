<template>
  <div :class="`KpiStatEditableBase--${type}`" class="KpiStatEditableBase is-column has-background-grey-lighter p-a-s">
    <div class="KpiStatEditableBase__head has-text-weight-bold m-b-s">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
    <div class="KpiStatEditableBase__body">
      <div class="KpiStatEditableBase__description">
        {{ description }}
      </div>
      <slot />
    </div>
    <div class="KpiStatEditableBase__footer m-t-s">
      <slot name="footer">
        <label class="KpiStatEditableBase__target">{{ $t('general.target') }}</label>
        <form-field
          :validator="v.value"
          :class="{ 'has-addons': suffix }"
          attribute="Value"
          class="KpiStatEditableBase__field"
        >
          <template slot="control">
            <p class="control is-expanded">
              <input
                v-model.number="computedValue"
                :disabled="disabledInput || !isActive"
                class="input"
                type="text"
                @input="handleInput"
              >
            </p>
            <p v-if="suffix" class="control">
              <a class="button is-static-white">
                {{ suffix }}
              </a>
            </p>
          </template>
        </form-field>
        <div class="is-flex m-t-s">
          <el-switch
            v-model="isActiveComputed"
            :disabled="disableSwitch"
          />
          <div class="is-expanded has-text-centered has-text-weight-semibold">
            {{ isActive ? $t('general.enabled') : $t('general.disabled') }}
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isActive: {
      type: Boolean,
      default: false
    },
    suffix: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: 0
    },
    v: {
      type: Object,
      default: () => ({
        $dirty: false,
        $error: false,
        $invalid: true,
        $pending: false,
        $params: []
      })
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    disableSwitch: {
      type: Boolean,
      default: false
    },
    dominant: {
      type: String,
      default: ''
    }
  },
  computed: {
    isActiveComputed: {
      get () {
        return this.isActive
      },
      set (value) {
        this.$emit('update:isActive', value)
      }
    },
    computedValue: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('update:value', value)
      }
    },
    isDominant () {
      return this.dominant === this.type
    }
  },
  methods: {
    handleInput () {
      this.$emit('switchDominant', this.type)
      this.v.value.$touch()
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.KpiStatEditableBase {
  &__body {
    flex: 1 1 auto;
  }
  &__description, &__target {
    color: $grey;
    font-size: .9em;
    line-height: 1;
  }
  &__field {
    .input {
      color: $primary;
      font-weight: bold;
    }
  }
}
</style>
