const { round } = require('../../../shared/js/utils/numberUtils.js')
// intentionally imported like this. Cypress does not transpile from node-modules folder

function calculatePenetrationFactor (impressions, marketPopulation) {
  let penetrationFactor = (impressions / marketPopulation) * 100
  penetrationFactor = round(penetrationFactor)

  return penetrationFactor
}

function calculateImpressions (penetrationFactor, marketPopulation) {
  let impressions = (penetrationFactor / 100) * marketPopulation
  return Math.round(impressions)
}

function calculateRoi (prValue, totalInvestment) {
  return round(((prValue - totalInvestment) / totalInvestment) * 100)
}

module.exports = {
  calculateImpressions,
  calculatePenetrationFactor,
  calculateRoi
}
